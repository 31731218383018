





































/* eslint-disable no-console */
import Vue from 'vue';

import DnbButton from '@/components/DnbButton.vue';
import DnbMenu from '@/components/DnbMenu.vue';
import FeatureFlag from '@/components/FeatureFlag.vue';
import SlideDownAlert from '@/components/SlideDownAlert.vue';

import '../styles/main.scss';

export default Vue.extend({
  name: 'app',

  components: {
    DnbButton,
    DnbMenu,
    FeatureFlag,
    SlideDownAlert,
  },

  computed: {
    showMenu(): boolean {
      const { path } = this.$route;
      return (
        path !== '/logout' && path !== '/login' && path !== '/access-denied'
      );
    },
    showLogout(): boolean {
      return this.$route.path !== '/logout';
    },
  },

  methods: {
    logout() {
      this.$router.push('/logout');
    },
  },
});
