import Vue from 'vue';
import Vuex from 'vuex';

import { featureFlags } from './featureFlags';
import { RootState } from './types';

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    featureFlags,
  },
});
