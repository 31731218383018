

















import Vue from 'vue';

import DnbIcon from '@/components/DnbIcon.vue';

enum Size {
  Small = 'small',
  Normal = 'normal',
  Natural = 'natural',
}

enum Style {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Danger = 'danger',
  Plain = 'plain',
  Warning = 'warning',
}

export default Vue.extend({
  name: 'dnb-button',
  components: {
    DnbIcon,
  },
  props: {
    to: {
      type: String,
      default: undefined,
    },
    buttonStyle: {
      type: String,
      default: Style.Primary,
    },
    icon: { type: String, default: undefined },
    size: { type: String, default: Size.Normal },
    title: { type: String, default: '' },
    disabled: { required: false, default: false, type: Boolean },
  },
  computed: {
    getClasses(): string[] {
      const style = this.getStyle(this.buttonStyle);
      const size = this.getSizeClasses(this.size);
      return ['dnb-button', style, size];
    },
  },
  methods: {
    getStyle(style: string): string {
      switch (style) {
        case Style.Primary:
          return this.$style.primary;
        case Style.Secondary:
          return this.$style.secondary;
        case Style.Tertiary:
          return this.$style.tertiary;
        case Style.Danger:
          return this.$style.danger;
        case Style.Plain:
          return this.$style.plain;
        case Style.Warning:
          return this.$style.warning;
      }
      return this.$style.primary;
    },
    getSizeClasses(size: string): string {
      switch (size) {
        case Size.Small:
          return this.$style.small_size;
        case Size.Natural:
          return this.$style.natural_size;
        case Size.Normal:
          return this.$style.normal_size;
      }
      return this.$style.normal_size;
    },
  },
});
