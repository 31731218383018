import { FeatureFlag } from '@portals/shared/admin/FeatureFlagDto';

import { FeatureFlagState } from './types';

export const getters = {
  hasFeatureFlags(state: FeatureFlagState) {
    return state.featureFlags !== null;
  },
  getFeatureFlag(state: FeatureFlagState): (key: FeatureFlag) => boolean {
    return (key) => {
      if (state.featureFlags) {
        return state.featureFlags[key] || false;
      }
      return false;
    };
  },
};
