import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { getAzureIdToken } from '@/azureAd';

export function getBasepath(): string {
  if (window.location.hostname === 'localhost') {
    return `${BACKEND_URL}/service`;
  }

  return `${window.location.origin}/service`;
}

interface RequestOptions extends AxiosRequestConfig {
  abortIfNoToken?: boolean;
}

export async function request<Response>(
  options: RequestOptions,
): Promise<AxiosResponse<Response>> {
  const token = getAzureIdToken();
  if (options.abortIfNoToken === true && !token) {
    throw new Error('Missing token');
  }
  const prefix = getBasepath();
  const requestOpt: AxiosRequestConfig = {
    method: options.method || 'GET',
    url: `${prefix}/${options.url}`,
    headers: {
      'aad-token': token,
    },
    data: options.data,
  };
  try {
    const response: AxiosResponse<Response> = await Axios(requestOpt);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 403) {
      localStorage.removeItem('idt');
      window.location.href = `/login`;
    }
    throw error;
  }
}
