











import { FeatureFlag } from '@portals/shared/admin/FeatureFlagDto';
import Vue from 'vue';

export default Vue.extend({
  props: {
    flag: {
      type: String as () => FeatureFlag,
      required: true,
    },
  },
  computed: {
    isFeatureFlagLoaded(): boolean {
      return this.$store.getters['featureFlags/hasFeatureFlags'];
    },
    shouldDisplayFeature(): boolean {
      if (!this.$store.getters['featureFlags/hasFeatureFlags']) {
        return false;
      }

      return this.$store.getters['featureFlags/getFeatureFlag'](this.flag);
    },
  },
});
