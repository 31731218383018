import { FeatureFlagsForCurrentAdmin } from '@portals/shared/admin/FeatureFlagDto';
import { MutationTree } from 'vuex';

import { FeatureFlagState } from './types';

export const mutations: MutationTree<FeatureFlagState> = {
  SET_FEATURE_FLAGS(state, featureFlags: FeatureFlagsForCurrentAdmin) {
    state.featureFlags = featureFlags;
  },
};
