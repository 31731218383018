











import Vue from 'vue';

import * as colors from '../colors';

function getColor(color: string) {
  // @ts-ignore
  if (colors[color]) {
    // @ts-ignore
    return colors[color];
  }
  return color;
}

export default Vue.extend({
  name: 'icon-component',
  props: {
    icon: {
      required: true,
      type: String,
    },
    size: {
      type: String,
      default: 'medium',
    },
    fill: {
      type: String,
      default: 'transparent',
    },
    stroke: {
      type: String,
      default: 'currentColor',
    },
  },
  computed: {
    strokeColor(): string {
      return getColor(this.stroke);
    },
    fillColor(): string {
      return getColor(this.fill);
    },
    iconClassName(): string {
      return this.icon ? this.icon.toLowerCase() : '';
    },
    sizeClass(): string {
      switch (this.size) {
        case 'xsmall':
          return 'dnb-icon--xsmall';

        case 'small':
          return 'dnb-icon--small';

        case 'large':
          return 'dnb-icon--large';

        case 'medium':
        default:
          return 'dnb-icon--medium';
      }
    },
  },
});
