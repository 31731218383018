

















import Vue from 'vue';

import DnbIcon from '@/components/DnbIcon.vue';

import { Route, routes } from '../router';

export default Vue.extend({
  name: 'dnb-menu',
  components: { DnbIcon },
  props: { show: { type: Boolean, default: true } },
  computed: {
    routes(): Route[] {
      return routes.filter((route) => route.meta && route.meta.showInMenu);
    },
  },
});
