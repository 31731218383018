import { Module } from 'vuex';

import { RootState } from '../types';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { FeatureFlagState } from './types';

export const state: FeatureFlagState = {
  featureFlags: null,
};

export const featureFlags: Module<FeatureFlagState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
