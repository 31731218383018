import { fetchFeatureFlagsForCurrentAdmin } from '@/service/featureFlagService';

import { Actions } from '../types';

export const actions: Actions = {
  async FETCH_FEATURE_FLAGS({ commit }) {
    try {
      const featureFlags = await fetchFeatureFlagsForCurrentAdmin();
      commit('SET_FEATURE_FLAGS', featureFlags);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.debug('Error in fetching of feature flags', error);
    }
  },
};
