import { LogLevel, PublicClientApplication } from '@azure/msal-browser';

export const AzTenant = AzureTenant || '4cbfea0a-b872-47f0-b51c-1c64953c3f0b';
export const AzClientID =
  AdminAzureClientId || '264ef1ae-d391-4632-b317-5eba2e17abb2';
export const AzRedirectURI =
  AdminAzureRedirectURI ||
  'https://admin.dev.developerportal.tech-03.net/manage';

const app = new PublicClientApplication({
  auth: {
    clientId: AzClientID,
    authority: `https://login.microsoftonline.com/${AzTenant}`,
    redirectUri: AzRedirectURI,
    navigateToLoginRequestUrl: false,
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Error,
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
});

export function loginWithAzure(): void {
  app.loginRedirect({
    scopes: ['openid', 'profile', 'email'],
    domainHint: 'dnb.no',
  });
}

export function logoutWithAzure(): void {
  localStorage.removeItem('aad_token');
}

export function getAzureIdToken(): string | void {
  const aad_token = localStorage.aad_token;

  if (aad_token) {
    const token = JSON.parse(aad_token);
    if (new Date() < new Date(token.expires)) {
      return token.access_token;
    } else {
      logoutWithAzure();
    }
  }
}

export function isloggedIn(): boolean {
  return !!getAzureIdToken();
}

export async function setAzureToken(): Promise<boolean> {
  const result = await app.handleRedirectPromise();

  if (!result) {
    return false;
  }

  const token = result.idToken;
  const expires = result.expiresOn?.valueOf();

  localStorage.setItem(
    'aad_token',
    JSON.stringify({
      access_token: token,
      expires,
    }),
  );

  return true;
}
