/* eslint-disable unicorn/prevent-abbreviations */
import Vue from 'vue';

import App from './components/App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

store.dispatch('featureFlags/FETCH_FEATURE_FLAGS');

const vue = new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>',
});

export { vue as default };
