/* GREENS */
export const seaGreen = '#007272';
export const seaGreenAlt = '#008484';
export const seaGreenAlt30 = '#B3DADA';
export const oceanGreen = '#00343e';
export const emeraldGreen = '#14555A';
export const summerGreen = '#28B482';
export const mintGreen = '#A5E1D2';
export const mintGreen25 = '#E9F8F4';
export const mintGreen50 = '#D2F0E9';
export const mintGreenAlt = '#EBFFFA';

/* SIGNALS */
export const signalOrange = '#FF5400';
export const signalYellow = '#FFFF7A';

/* GREYS */
export const coal = '#333333';
export const darkGrey = '#757575';
export const softGrey = '#cccccc';
export const outlineGrey = '#EBEBEB';
export const subtleGrey = '#F8F8F8';
export const lightGrey = '#FAFAFA';

/* INDIGOS */
export const indigo = '#23195a';
export const indigo66 = '#6e6892';
export const indigo33 = '#b7b3c9';
export const violet = '#6e2382';
export const violet66 = '#A06EAD';
export const violet33 = '#CFB7D6';

/* BLUES */
export const skyBlue = '#4BBED2';
export const skyBlue66 = '#89D4E2';
export const skyBlue33 = '#C4EAF0';

/* PINK */
export const pink = '#E10076';
export const hoverOnPink = '#5a1948';
export const cherryRed8 = '#fdebf4';
export const lightPink = '#E2D3E6';

/* Yellow */
export const sandYellow = '#FBF6EC';
