import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';

import { isloggedIn } from '@/azureAd';

export { RouteConfig as Route };

Vue.use(Router);

export const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Landing',
    component: () => import('@/views/Landing.vue'),
    meta: {
      authenticationRequired: true,
    },
  },
  {
    path: '/login',
    name: 'AzureLogin',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/manage', // changing this requires config change in azure
    name: 'Manage',
    component: () => import('@/views/Manage.vue'),
  },
  {
    path: '/logout',
    name: 'AzureLogout',
    component: () => import('@/views/Logout.vue'),
  },
  {
    path: '/apis',
    name: 'APIs',
    component: () => import('@/views/Apis/Apis.vue'),
    meta: {
      authenticationRequired: true,
      showInMenu: true,
      menuIcon: 'API',
    },
  },
  {
    path: '/apis/new',
    name: 'CreateApi',
    component: () => import('@/views/Apis/EditApi.vue'),
    meta: {
      authenticationRequired: true,
    },
  },
  {
    path: '/apis/:apiId/edit',
    name: 'EditApi',
    component: () => import('@/views/Apis/EditApi.vue'),
    meta: {
      authenticationRequired: true,
    },
  },
  {
    path: '/api-incidents',
    name: 'Incidents',
    component: () => import('@/views/ApiIncidents/ApiIncidents.vue'),
    meta: {
      authenticationRequired: true,
      showInMenu: true,
      menuIcon: 'AlertCircle',
    },
  },
  {
    path: '/api-incidents/new',
    name: 'CreateApiIncident',
    component: () => import('@/views/ApiIncidents/EditApiIncident.vue'),
    meta: {
      authenticationRequired: true,
    },
  },
  {
    path: '/api-incidents/:id/edit',
    name: 'EditAPIIncident',
    component: () => import('@/views/ApiIncidents/EditApiIncident.vue'),
    meta: {
      authenticationRequired: true,
    },
  },
  {
    path: '/subscribers',
    name: 'Subscribers',
    component: () => import('@/views/ApiSubscribers/Subscribers.vue'),
    meta: {
      authenticationRequired: true,
      showInMenu: true,
      menuIcon: 'ReadEmailHand',
    },
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('@/views/News/News.vue'),
    meta: {
      authenticationRequired: true,
      showInMenu: true,
      menuIcon: 'Send',
    },
  },
  {
    path: '/news/post/:id?',
    name: 'Post',
    component: () => import('@/views/News/Post.vue'),
    meta: {
      authenticationRequired: true,
    },
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import('@/views/Feedback/Feedback.vue'),
    children: [
      {
        path: ':id',
        name: 'FeedbackById',
        component: () => import('@/views/Feedback/ViewFeedback.vue'),
      },
    ],
    meta: {
      authenticationRequired: true,
      showInMenu: true,
      menuIcon: 'Support',
    },
  },
  {
    path: '/users',
    name: 'User info',
    component: () => import('@/views/Users/UserInfo.vue'),
    meta: {
      authenticationRequired: true,
      showInMenu: true,
      menuIcon: 'MyProfile',
    },
  },
  {
    path: '/users/:id',
    name: 'ViewUser',
    component: () => import('@/views/Users/ViewUser.vue'),
    meta: {
      authenticationRequired: true,
    },
  },
  {
    path: '/organizations',
    name: 'Organizations',
    component: () => import('@/views/Organizations/Organizations.vue'),
    meta: {
      authenticationRequired: true,
      showInMenu: true,
      menuIcon: 'BuildingFlag',
    },
  },
  {
    path: '/organizations/new',
    name: 'CreateOrganization',
    component: () => import('@/views/Organizations/EditOrganization.vue'),
    meta: {
      authenticationRequired: true,
    },
  },
  {
    path: '/organizations/:id',
    name: 'ViewOrganization',
    component: () => import('@/views/Organizations/ViewOrganization.vue'),
    meta: {
      authenticationRequired: true,
    },
  },
  {
    path: '/organizations/:id/edit',
    name: 'EditOrganization',
    component: () => import('@/views/Organizations/EditOrganization.vue'),
    meta: {
      authenticationRequired: true,
    },
  },
  {
    path: '/apiUsage',
    name: 'apiUsage',
    component: () => import('@/views/ApiUsage.vue'),
    meta: {
      authenticationRequired: true,
    },
  },
  {
    path: '/api-grants/:appId',
    name: 'ApiGrant',
    component: () => import('@/views/ApiGrant/ApiGrant.vue'),
    meta: {
      authenticationRequired: true,
    },
  },
  {
    path: '/api-grants',
    name: 'Grants',
    component: () => import('@/views/ApiGrants/ApiGrants.vue'),
    meta: {
      authenticationRequired: true,
      showInMenu: true,
      menuIcon: 'Live',
    },
  },
  {
    path: '/feature-flags',
    name: 'Feature flags',
    component: () => import('@/views/FeatureFlags/FeatureFlags.vue'),
    meta: {
      authenticationRequired: true,
      showInMenu: true,
      menuIcon: 'Flag',
    },
  },
  {
    path: '/feature-flags/new',
    name: 'CreateFeatureFlag',
    component: () => import('@/views/FeatureFlags/EditFeatureFlag.vue'),
    meta: {
      authenticationRequired: true,
    },
  },
  {
    path: '/feature-flags/:id/edit',
    name: 'EditFeatureFlag',
    component: () => import('@/views/FeatureFlags/EditFeatureFlag.vue'),
    meta: {
      authenticationRequired: true,
    },
  },
  {
    path: '/tags',
    name: 'Tags',
    component: () => import('@/views/Tags/Tags.vue'),
    meta: {
      authenticationRequired: true,
      showInMenu: true,
      menuIcon: 'NameTag',
    },
  },
  {
    path: '/tags/tag/:id?',
    name: 'Tag',
    component: () => import('@/views/Tags/Tag.vue'),
    meta: {
      authenticationRequired: true,
    },
  },
  {
    path: '/teams/:id',
    name: 'ViewTeam',
    component: () => import('@/views/Teams/ViewTeam.vue'),
    meta: {
      authenticationRequired: true,
    },
  },
  {
    path: '/access-denied',
    name: 'AccessDenied',
    component: () => import('@/views/errors/AccessDenied.vue'),
  },
  {
    path: '/icons',
    name: 'Icons',
    component: () => import('@/views/Icons.vue'),
    meta: {
      authenticationRequired: true,
      showInMenu: true,
      menuIcon: 'Sandbox',
    },
  },
  {
    path: '/config',
    name: 'Config',
    component: () => import('@/views/RuntimeConfig/RuntimeConfig.vue'),
    meta: {
      authenticationRequired: true,
      showInMenu: true,
      menuIcon: 'SharedLayer',
    },
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/errors/PageNotFound.vue'),
  },
];

const router = new Router({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.authenticationRequired && !isloggedIn()) {
    sessionStorage.setItem('nextPath', to.path);
    next({ name: 'AzureLogin' });
  } else {
    next();
  }
});

export default router;
