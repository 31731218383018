import {
  FeatureFlagDto,
  FeatureFlagsForCurrentAdmin,
} from '@portals/shared/admin/FeatureFlagDto';

import { request } from './request';

export async function fetchFeatureFlagsForCurrentAdmin(): Promise<FeatureFlagsForCurrentAdmin> {
  const response = await request<FeatureFlagsForCurrentAdmin>({
    url: 'flags',
    abortIfNoToken: true,
  });
  return response.data;
}

export async function fetchFeatureFlags(): Promise<FeatureFlagDto[]> {
  const response = await request<FeatureFlagDto[]>({
    url: 'feature-flags',
  });
  return response.data;
}

export async function fetchFeatureFlag(id: string): Promise<FeatureFlagDto> {
  const response = await request<FeatureFlagDto[]>({
    url: 'feature-flags',
  });
  const flag = response.data.find((flag) => flag.id === id);
  if (flag) {
    return flag;
  }
  throw new Error('Could not find feature flag');
}
